import { inject, Injectable, signal } from '@angular/core';
import { IconName, IconSize } from '@san/tools/models';
import { divIcon, DivIconOptions, FeatureGroup, LatLng, LatLngExpression, Layer, map, Map, marker, Marker, TileLayer, tileLayer } from 'leaflet';
import 'leaflet.fullscreen';
import { uniqueId } from 'lodash-es';
import { GoogleTileMode, TileType } from '../models/enum/map.enum';
import { RdvStep } from '../models/enum/rdv.enum';
import { MarkerItem, MarkerLayer } from '../models/interfaces/map.interface';
import { NavigationService } from './navigation.service';
import { NotificationService } from './notifaction.service';
import { TraductorService } from './traductor.service';

@Injectable({
  providedIn: 'root',
})
export class MapService {

  private readonly traductor = inject(TraductorService);
  private readonly notificationService = inject(NotificationService);
  private readonly navigationService = inject(NavigationService);

  public readonly mapCenter: LatLngExpression = [5.3096600, -4.0126600];
  public readonly defaultZoom = 12;

  public readonly displaySpinner = signal(false);

  static get options(): PositionOptions {
    return { enableHighAccuracy: true };
  }

  public static getCurrentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error),
        MapService.options
      );
    });
  }

  /**
   * Init map and basics controls
   * @param container
   * @param center
   * @param zoom
   * @returns
   */
  initMap(container: string, center?: LatLngExpression, zoom?: number): Map {
    return map(container, {
      center: center ?? this.mapCenter,
      maxZoom: 18,
      minZoom: 3,
      zoom: zoom ?? this.defaultZoom,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 'topleft',
        title: this.traductor.translate('core.map.fullscreen'),
        titleCancel: this.traductor.translate('core.map.leave-fullscreen')
      }
    });
  }

  /**
   * Current location for user
   * @param callback
   */
  findMe(callback: PositionCallback) {
    this.displaySpinner.set(true);
    MapService.getCurrentPosition().then(position => {
      this.displaySpinner.set(false);
      callback(position as GeolocationPosition);
    }).catch(() => {
      this.notificationService.error('shared.localisation-error');
      this.displaySpinner.set(false);
    });
  }

  /**
   * Search and add your current GPS position on map
   * @param coords
   * @param map
   * @returns
   */
  addMyPositionMarker(coords: LatLngExpression, map: Map): Marker {
    const icon = MapService.createDivIcon(IconName.LOCATE_ME, 'warn', '4x', { iconAnchor: [20, 20] });
    const mk = marker(coords, { icon })
      .bindTooltip(this.traductor.translate('core.map.my-position'), { direction: 'top', offset: [0, -20] })
      .openTooltip();

    mk.addTo(map);
    map.flyTo(coords, map.getMaxZoom(), { duration: 3 });
    return mk;
  }

  static initMarkerMenu() {
    const div = document.createElement('div');
    div.id = uniqueId('marker-menu');
    div.className += 'marker-menu';
    return div;
  }

  showDirection(end: LatLng) {
    this.findMe(position => {
      const start = MapService.geolocationToLatLng(position);
      this.openMapDirection(start, end);
    });
  }

  /**
   * Create Layer group and add markers on it
   * @param markers
   * @param permanentTooltip
   * @returns
   */
  createMarkersLayer(markers: MarkerItem[], permanentTooltip = false): MarkerLayer {
    let mapMarkers: Marker[] = [];
    if (markers?.length) {
      mapMarkers = markers.map(mk => {
        const icon = MapService.createDivIcon(mk.icon, mk.iconColor);
        let m = marker(mk.position, { icon });
        m = MapService.addTooltip(m, mk.tooltip ?? mk.label, permanentTooltip, mk.iconColor);

        const div = MapService.initMarkerMenu();

        const pDirection = document.createElement('p');
        pDirection.onclick = () => this.showDirection(mk.position);
        pDirection.innerHTML = `Afficher l'itinéraire`;
        pDirection.className += 'pointer';
        div.appendChild(pDirection);
        const pBooking = document.createElement('p');
        pBooking.onclick = () => this.navigationService.openBooking(null, mk.data, RdvStep.DATE);
        pBooking.innerHTML = `Prendre un rendez-vous`;
        pBooking.className += 'pointer';
        div.appendChild(pBooking);

        m.bindPopup(() => div, {
          offset: [0, -30],
          closeOnClick: true,
          closeOnEscapeKey: true,
          autoClose: true,
          closeButton: true,
          className: 'mk-popup'
        });
        m.on('click', () => {
          if (mk.click) {
            mk.click(mk, m);
          }
        });
        return m;
      });
    }
    return { group: new FeatureGroup(mapMarkers), markers: mapMarkers };
  }

  /**
   * Add tooltip to marker
   * @param m
   * @param tooltip
   * @param permanentTooltip
   * @param color
   * @returns
   */
  static addTooltip(m: Marker, tooltip: string, permanentTooltip?: boolean, color: string = 'warn') {
    if (tooltip?.length) {
      m.bindTooltip(tooltip, { direction: 'right', className: `mk-tooltip-${color}`, offset: [15, -20], permanent: permanentTooltip });
      if (permanentTooltip) {
        m.openTooltip();
      }
    }
    return m;
  }

  /**
   * Remove a layer from map
   * @param map
   * @param layer
   */
  static removeLayer(map: Map, layer: Layer) {
    if (layer) {
      map.removeLayer(layer);
    }
  }

  /**
   * Add an isolate marker on map
   * @param coords
   * @param map
   * @param iconName
   * @param color
   * @param size
   * @param options
   * @returns
   */
  static addMarker(coords: LatLngExpression, map: Map,
    iconName = IconName.LOCATION,
    color: string = 'primary',
    size: IconSize = '4x',
    options: DivIconOptions = { iconAnchor: [16, 42] }): Marker {

    const icon = MapService.createDivIcon(iconName, color, size, options);
    const mk = marker(coords, { icon });
    mk.addTo(map);
    return mk;
  }

  /**
   * Create DivIcon
   * @param icon
   * @param color
   * @param size
   * @param options
   * @returns
   */
  static createDivIcon(icon: IconName = IconName.LOCATION, color: string = 'warn', size: IconSize = '4x', options: DivIconOptions = { iconAnchor: [16, 42] }) {
    return divIcon({
      className: 'icon-div',
      html: `<i class="fa-solid fa-${icon} text-${color} fa-${size}"></i>`,
      ...(options ?? {})
    });
  }

  /**
   * Get google tile layer
   * @param mode
   * @returns
   */
  static getGoogleTileLayer(mode: GoogleTileMode) {
    return tileLayer(`http://{s}.google.com/vt?lyrs=${mode}&x={x}&y={y}&z={z}`, {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '&copy; <a href="https://www.google.fr/maps">Google Maps</a>'
    });
  }

  static getOsmTileLayer() {
    return tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>'
    });
  }

  static geolocationToLatLngExp(position: GeolocationPosition): LatLngExpression {
    return [position.coords.latitude, position.coords.longitude];
  }

  static geolocationToLatLng(position: GeolocationPosition): LatLng {
    return new LatLng(position.coords.latitude, position.coords.longitude);
  }

  static gpsToLatLng(lat: string, lng: string): LatLng {
    if (lat?.length && lng?.length) {
      return new LatLng(+lat, +lng);
    }
    return null;
  }

  /**
   * Change map tile layer
   * @param map
   * @param tileType
   * @param currentTile
   * @returns
   */
  static changeTileLayer(map: Map, tileType: TileType, currentTile?: TileLayer) {
    if (currentTile) {
      map.removeLayer(currentTile);
    }
    const tile = tileType === 'OSM' ? this.getOsmTileLayer() : MapService.getGoogleTileLayer(tileType);
    tile.addTo(map);
    map.invalidateSize();
    return { map, tile };
  }

  openMapDirection(start: LatLng, end: LatLng) {
    window?.open(`https://www.google.fr/maps/dir/${start.lat},${start.lng}/${end.lat},${end.lng}/`, '_blank');
  }

}
