import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { PhotoComponent } from '../../photo/photo.component';
import { Utilisateur } from './../../../models/entity/Utilisateur';
import {
  DisplayDatePipe,
  DisplayValuePipe,
} from './../../../pipes/object.pipe';
import { GenrePipe } from './../../../pipes/user.pipe';

@Component({
  selector: 'rdv-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    GenrePipe,
    DisplayDatePipe,
    DisplayValuePipe,
    TranslatePipe,
    PhotoComponent,
  ],
})
export class UserInfoComponent {
  @Input() user: Utilisateur;
  @Input() editable: boolean = false;
}
