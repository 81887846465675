import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { SanButtonComponent } from '@san/tools/ui/button';
import { Etablissement } from '../../models/entity/etablissement';
import {
  DisplayBoolPipe,
  DisplayDatePipe,
  DisplayValuePipe,
} from '../../pipes/object.pipe';
import { StateIconComponent } from '../state-icon/state-icon.component';

@Component({
  selector: 'rdv-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslatePipe,
    SanButtonComponent,
    DisplayValuePipe,
    DisplayBoolPipe,
    DisplayDatePipe,
    StateIconComponent,
  ],
})
export class HospitalComponent {
  @Input() etablissement: Etablissement;
  @Input() readOnly = true;
}
