<san-modal header="profil-medecin.edit-diplome.title" (closeModal)="close()">
  <div content>
    <form [formGroup]="diplomeForm">

      <san-input type="text" [maxLength]="150" idControl="diplome-libelle"
        placeholder="profil-medecin.edit-diplome.libelle-plh" label="profil-medecin.edit-diplome.libelle"
        formControlName="libelle"></san-input>

      <san-input type="text" [maxLength]="230" idControl="diplome-etablissement"
        label="profil-medecin.edit-diplome.etablissement" placeholder="profil-medecin.edit-diplome.etablissement-plh"
        formControlName="etablissement"></san-input>

      <san-input type="number" [maxLength]="4" [minLength]="4" idControl="diplome-annee"
        label="profil-medecin.edit-diplome.annee" placeholder="profil-medecin.edit-diplome.annee-plh"
        formControlName="annee"></san-input>

    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon idControl="cancel-diplome-btn" (onclick)="close()" [icon]="IconName.CLOSE" color="warn"
      label="shared.annuler"></san-button-icon>
    <san-button-icon idControl="validate-diplome-btn" [icon]="IconName.CHECK" color="primary" (onclick)="save()"
      [disabled]="!diplomeForm.valid" label="shared.valider"></san-button-icon>
  </div>
</san-modal>
