import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, inject, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { IconName } from '@san/tools/models';
import { SanLoaderDirective } from '@san/tools/shared';
import { BaseComponent } from '@san/tools/utils';
import { Control, LatLng, LatLngExpression, LeafletMouseEvent, Map, Marker, TileLayer } from 'leaflet';
import 'leaflet.fullscreen';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import '../../../../../node_modules/leaflet-cascade-buttons/src/L.cascadeButtons.js';
import { TileType } from '../../models/enum/map.enum';
import { MapClickMode, MarkerItem, MarkerLayer } from '../../models/interfaces/map.interface';
import { MapService } from '../../services/map.service';
import { SsrService } from '../../services/ssr.service.js';
import { TraductorService } from '../../services/traductor.service';

@Component({
  selector: 'rdv-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SanLoaderDirective]
})
export class MapComponent extends BaseComponent implements OnChanges, AfterViewInit, OnDestroy {

  protected readonly mapService = inject(MapService);
  private readonly traductor = inject(TraductorService);
  private readonly ngZone = inject(NgZone);
  private readonly ssrService = inject(SsrService);

  @Input() makers: MarkerItem[] = [];
  @Input() position: LatLng;
  @Input() clickMode: MapClickMode = 'Marker';
  @Input() mapClickMarkerIcon: IconName = IconName.LOCATION;
  @Input() mapClickMarkerIconColor: string = 'warn';
  @Input() mapClickMarkerTooltip: string;
  @Input() permanentTooltip: boolean = false;
  @Input() tileType: TileType = 'OSM';
  @Input() width: string = 'auto';
  @Input() height: string = '500px';
  @Output() clickOnMap: EventEmitter<LatLng> = new EventEmitter<LatLng>;
  @Output() centerChange: EventEmitter<LatLng> = new EventEmitter<LatLng>;
  @Output() locateMe: EventEmitter<LatLngExpression> = new EventEmitter<LatLngExpression>;
  @Output() loaded: EventEmitter<Map> = new EventEmitter<Map>;

  readonly IconName = IconName;
  private map: Map;
  private currentTile: TileLayer;
  private myPositionMarker: Marker;
  protected mapClickMarker: Marker;
  protected markersLayer: MarkerLayer;
  movedMap: Subject<void> = new Subject<void>();

  private fiBounds() {
    if (this.markersLayer?.markers?.length) {
      this.map.fitBounds(this.markersLayer.group?.getBounds());
    }
  }

  private addControls(map: Map) {
    if (this.ssrService.isBrowser()) {
      new (Control as any).cascadeButtons([
        {
          icon: 'fas fa-arrows-to-dot',
          title: this.traductor.translate('core.map.center-positions'),
          ignoreActiveState: true,
          command: () => this.fiBounds()
        },
        {
          icon: 'fas fa-crosshairs',
          title: this.traductor.translate('core.map.my-position'),
          ignoreActiveState: true,
          command: () => this.mapService.findMe((position: GeolocationPosition) => {
            const coords = MapService.geolocationToLatLng(position);
            this.locateMe.next(coords);
            MapService.removeLayer(this.map, this.myPositionMarker);
            this.myPositionMarker = this.mapService.addMyPositionMarker(coords, this.map);
          })
        }
      ], { ignoreActiveState: true, position: 'topleft', direction: 'vertical' }).addTo(map);
    }
    return map;
  }

  private initMap(): void {
    if (this.ssrService.isBrowser()) {
      this.map = this.mapService.initMap('rdv-map');
      this.map = this.addControls(this.map);
      this.changeTile();
      this.map.on('click', (event: LeafletMouseEvent) => this.handleMapClick(event.latlng));
      this.map.on('load', () => {
        this.loaded.next(this.map);
        this.map.invalidateSize();
      });
    }
  }

  private changeTile() {
    if (this.map) {
      const { map, tile } = MapService.changeTileLayer(this.map, this.tileType, this.currentTile);
      this.map = map;
      this.currentTile = tile;
    }
  }

  private updateMarkersLayer() {
    if (this.markersLayer) {
      this.markersLayer.group.clearLayers();
    }
    if (this.map) {
      this.markersLayer = this.mapService.createMarkersLayer(this.makers, this.permanentTooltip);
      this.markersLayer.group.addTo(this.map);
      this.fiBounds();
    }
  }

  private handleMapClick(latlng: LatLng) {
    if (latlng) {
      this.clickOnMap.next(latlng);
      if (this.clickMode !== 'EventOnly') {
        MapService.removeLayer(this.map, this.mapClickMarker);
        let marker = MapService.addMarker(latlng, this.map, this.mapClickMarkerIcon, this.mapClickMarkerIconColor);
        marker = MapService.addTooltip(marker, this.mapClickMarkerTooltip, true);
        marker.on('click', () => marker.openPopup());
        this.mapClickMarker = marker;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tileType) {
      this.changeTile();
    }
    if (changes.makers) {
      this.updateMarkersLayer();
    }
    if (changes.position && this.position) {
      this.handleMapClick(this.position);
      this.map?.flyTo(this.position, 13, { animate: false });
    }
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.initMap();
      this.updateMarkersLayer();
      // this.map.on('moveend', () => this.movedMap.next());
    });
    this.movedMap.pipe(debounceTime(2000), takeUntil(this.destroy)).subscribe(() => this.centerChange.next(this.map?.getCenter()));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.markersLayer?.group?.clearLayers();
    this.map.removeLayer(this.markersLayer?.group);
    this.markersLayer?.group?.remove();
    this.map.clearAllEventListeners();
    this.map.stop();
    this.map.remove();
    this.map = null;
  }

}
