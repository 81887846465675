import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanInputComponent } from '@san/tools/ui/input';
import { SanModalComponent } from '@san/tools/ui/modal';
import { DiplomeService } from './../../../../api/diplome.service';
import { Utilisateur } from './../../../../models/entity/Utilisateur';
import { Diplome } from './../../../../models/entity/diplome';
import { ReactiveService } from './../../../../services/reactive.service';
import { UserService } from './../../../../services/user.service';

@Component({
  selector: 'rdv-medecin-diplome-dialog',
  templateUrl: './medecin-diplome-dialog.component.html',
  styleUrls: ['./medecin-diplome-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SanModalComponent,
    SanInputComponent,
    SanButtonIconComponent,
  ],
})
export class MedecinDiplomeDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  private readonly reactiveService = inject(ReactiveService);
  private readonly userService = inject(UserService);
  private readonly diplomeService = inject(DiplomeService);

  diplome: Diplome;
  user: Utilisateur = this.userService.connectedPro();
  diplomeForm: FormGroup;
  readonly IconName = IconName;

  ngOnInit(): void {
    this.diplome = this.data?.content as Diplome;
    this.diplomeForm = new FormGroup({
      libelle: new FormControl(this.diplome?.libelle, Validators.required),
      etablissement: new FormControl(
        this.diplome?.etablissement,
        Validators.required
      ),
      annee: new FormControl(this.diplome?.annee, Validators.required),
    });
  }

  save() {
    const data = this.diplomeForm.getRawValue();
    const request = {
      ...data,
      medecin: this.user.medecin,
      id: this.diplome?.id,
    };
    this.reactiveService.call(
      this.diplomeService.save(request),
      (res) => {
        this.diplome = { ...this.diplome, ...data, id: res.data?.id };
        this.close({ content: this.diplome });
      },
      true
    );
  }
}
